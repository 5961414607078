import React, { useState, useRef} from 'react'
import './TempLanding.css'
import logo from '../../../Assets/logo-stacked.png'
import LoginIcon from '@mui/icons-material/Login';
import { Link } from 'react-router-dom'
import abwb from '../../../Assets/abwb.png'
import fb from '../../../Assets/fb.png'
import insta from '../../../Assets/insta.png'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify'

export default function TempLanding() {

    const [name, setName] = useState("")
    const [act, setAct] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [showreel, setShowreel] = useState("")

    const [sent, setSent] = useState(false)

    const form = useRef()

    function sendEmail(e) {
        e.preventDefault();
        
       if(!name || !act || !email || !number || !showreel){
            toast.error("Please fill in all available fields");
            
        } else{
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then(() => {
                toast.success("Successfully sent");
                form.current.reset()
                setSent(true)
            }, (error) => {
                toast.error(error.text);
            });
        }
      };

  return (
    <div id='temp-page'>

        <Link style={{textDecoration:'none'}} to='/artists' id='temp-login'> 
            <p style={{ color:'white', fontWeight:'bold'}}>Artist login</p> 
            <LoginIcon sx={{color:'white', ml:1}}/>
        </Link>


        <img id='landing-logo' src={logo} alt="First dance wedding music logo"/>

       {!sent ?
        <div style={{textAlign:'center'}}>


                <p className='landing-txt' style={{width:650, maxWidth: '100vw'}}>
                    The exciting new platform from First Dance Music is launching soon with a desire to challenge the way wedding entertainment is approached by both clients and artists. 
                    <br/><br/>
                    <b>Our mission -</b> to ensure that whilst the happy couple remains happy, the musicians do too.
                </p>


            <h3 style={{fontFamily:'Mont', color: '#1e1e1e', margin: '40px auto', width:650, maxWidth: '90vw'}}>Want to be a part of a positive change within the wedding entertainment industry?</h3>

            <form id='temp-form' ref={form} onSubmit={sendEmail}>
               
                    <input placeholder='Name' name='Name' value={name} onChange={(e)=> setName(e.target.value)}/>
               
                    <input placeholder='Act name' name='Act' value={act} onChange={(e)=> setAct(e.target.value)}/>
               
                    <input placeholder='Email address' name='Email' value={email} onChange={(e)=> setEmail(e.target.value)}/>
                   
                    <input placeholder='Contact number' name='Number' value={number} onChange={(e)=> setNumber(e.target.value)}/>
               
                    <input placeholder='Link to showreel' name='Showreel' value={showreel} onChange={(e)=> setShowreel(e.target.value)}/>

                <button>
                    Submit
                </button>

                    <p style={{fontSize:'0.8rem', marginBottom: 50}}>
                    By submitting this form, I consent to First Dance Music storing the above information to contact me regarding any services offered by First Dance Music.
                    </p>
            </form>

        </div>
        :
        

        <div style={{textAlign:'center'}}>
            <h2>Thanks for your submission!</h2>

            <p className='landing-txt'>
                One of our Entertainment Experts will be in touch to discuss your Artist Profile in the coming days. <b>#WeAreFirstDance</b>
            </p>
        </div>

       }
        <footer id='temp-footer'>
            <img style={{width: 150}} src={abwb}/>

            <p>First Dance Music Ltd © {new Date().getFullYear()}</p>

            <div>
                <a href='https://www.facebook.com/wearefirstdance' target="_blank"> 
                    <img style={{width: 40}} src={fb}/>
                </a>
                <a href='https://www.instagram.com/wearefirstdance' target="_blank">
                    <img style={{width: 40, marginLeft:5}} src={insta}/>
                </a>

            </div>
        </footer>
        
    </div>
  )
}
