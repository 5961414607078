import React, { useEffect, useState} from 'react'
import './Blog.css'
import { Link, useParams} from 'react-router-dom'
import axios from 'axios'

export default function Blog() {

    let { id } = useParams()

    const [blog, setBlog] = useState()

    useEffect(() => {
        getBlog()
    }, [])

        const getBlog = () => {
            axios
              .post(`${process.env.REACT_APP_API}/blogs/get-blog-by-id`, {blogId: id})
              .then((res) => {
                  console.log(res.data)
                setBlog(res.data)
              })
              .catch((e) => {
                console.log(e)
              })
          }
    

  return (
    <div style={{minHeight: '90vh', display:'flex', justifyContent:'center', flexDirection: 'column'}}>

        <div className='blog-header'>
            <img className='blog-header-img' src={blog?.headerImg} alt={blog?.title}/>
            <div>
                <h1 className='blog-header-title'>{blog?.title}</h1>
            </div>
            
            <div id='white-overlay' />

        </div>

        <p className='blog-subheader'>{blog?.subHeader}</p>

        <p className='blog-intro'>{blog?.intro}</p>

        <img className='secondary-img' src={blog?.secondaryImg} alt={blog?.title} />

        <div className='json-html' dangerouslySetInnerHTML={ { __html: blog?.html } }></div>

        <div className='closing-para' dangerouslySetInnerHTML={ { __html: blog?.closingPara } }></div>

        <div className='faqs'>
            <h2 style={{color: '#ecbdba', fontFamily:'Mont', textAlign:'center', padding: '1rem', borderBottom: '1px solid grey', margin: 0}}>FAQs</h2>

            {blog?.faqs.map((faq, i) => {
                return <Link key={i} to={`/blogs/${faq.link}`}>
                    <div  className='faq-item'>
                    <p>{faq.title}</p>
                    </div>
                </Link>
            })}

        </div>

    </div>
  )
}
