import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import Footer from './Footer'
import SendIcon from '@mui/icons-material/Send';
import Navbar from './Navbar';

export default function PublicUI() {
  return (
    <div>
      <Navbar/>
    
        <Outlet/>
        <Footer />
    </div>
  )
}
