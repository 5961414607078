import React, { useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment'
import { toast} from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'


// Description:
//   Page allows an artist to provide a quote in response to a client's request
//   Can also be revisited to update a previously supplied quote
//   Once a quote has been accepted (paid) this page will reflect that, no further
//   updates are permitted

export default function Request() {
    const vatRateLow = 1.0
    const vatRateHigh = 1.20
    const AppCommission = 1.125
    let { currentUser } = useAuth()
    let { id } = useParams()
    const [quoteId, setQuoteId] = useState(null)
    const [artistFee, setArtistFee] = useState(0)
    const [car, setCar] = useState(0)
    const [food, setFood] = useState(0)
    const [accommodation, setAccommodation] = useState(0)
    const [vat, setVat] = useState(vatRateLow)
    const [hasPaid, setHasPaid] = useState(false)
    const [selectedActId, setSelectedActId] = useState("")
    const [msg, setMsg] = useState("")
    const [artistName, setArtistName] = useState("")
    const [artistEmail, setArtistEmail] = useState("")
    const [request, setRequest] = useState("")
    const [acts, setActs] = useState([])
    const [requestRejected, setRequestRejected] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        getRequest()
        getArtistInfo()
    }, [])

    useEffect(() => {
        if ((currentUser.uid) && (request._id))
            getQuote()
    }, [currentUser.uid, request._id])

    useEffect(() => {
        if (artistEmail)
            getActInfo()
    }, [artistEmail])

    const getRequest = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-request-by-id`, {id})
        .then((res) => {
            setRequest(res.data)
            console.log('Request', res.data)

            // in the case of an artist specific request the act will already
            // be specified by the client and should be pulled into the quote
            if (res.data.actName) {
                setSelectedActId(res.data.actName)
            }

            // determine archived state
            const isArchived = res.data.archived?.some(
                (archiveEntry) => archiveEntry.artistId === currentUser.uid
            );
            setRequestRejected(isArchived)
        })
        .catch((e) => console.log(e))
    }

    const getArtistInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/users/get-artist-by-id`, {id: currentUser.uid})
        .then((res) => {
            let {firstName, lastName, email} = res.data
            setArtistName(`${firstName} ${lastName}`); setArtistEmail(email)
        })
        .catch((e) => console.log(e))
    }

    // retrieve all acts for the artist
    const getActInfo = () => {
        axios.post(`${process.env.REACT_APP_API}/acts/get-acts-by-id`, {artistId: currentUser.uid, published: true})
        .then((res) => {
            setActs(res.data);
        })
        .catch((e) => console.log(e))
    }

    const getQuote = () => {
        axios.post(`${process.env.REACT_APP_API}/quotes/get-artist-quote-for-request`, {
            artistId: currentUser.uid,
            requestId: request._id})
        .then((res) => {
            if (res.data) {
                setQuoteId(res.data._id ?? null)
                setArtistFee(res.data.artistFee ?? 0)
                setCar(res.data.mileageFee ?? 0)
                setFood(res.data.foodFee ?? 0)
                setAccommodation(res.data.accommodationFee ?? 0)
                setVat(res.data.vatRate ?? vatRateLow)
                setMsg(res.data.message ?? "")
                setHasPaid(res.data.hasPaid ?? "")
                setSelectedActId(res.data.selectedActId)
            }
        })
        .catch((e) => {
            console.log(e)
        })
    }

    let total = (artistFee*1)+(car*1)+(food*1)+(accommodation*1)
    let gross = Math.round(total*vat)


    const roundToNearest = (amountToRound, roundPoint) => {
        return Math.round(amountToRound / roundPoint) * roundPoint;
    }

    const sendQuote = () => {
        if (!total || !selectedActId) {
            toast.error("Please create a quote and select act")
        } else {
            setIsProcessing(true)
            // apply app commission to the gross artist cost for storing in the quote
            // but first round this figure to a customer friendly value
            const  customerGross = roundToNearest(gross * AppCommission, 5)

            // if no previous quote then the quoteId will be undefined and this will form a create
            // if update then potentially we are overwriting existing values with the same ones
            // since all are always applied
            let quotePayload = {
                quoteId,
                artistId: currentUser.uid,
                requestId: request._id,
                artistFee,
                mileageFee: car,
                foodFee: food,
                accommodationFee: accommodation,
                vatRate: vat,
                customerGross: customerGross,
                hasPaid: false,
                selectedActId: selectedActId,
                message: msg,
                updatedAt: new Date()
            }

            let requestPayload = {
                artistId: currentUser.uid,
                artistName,
                artistEmail,
                net: total,
                vat,
                msg,
                clientId: request.clientId,
                clientEmail: request.email,
                clientName: request.name,
                requestId: request._id
            }

            axios.post(`${process.env.REACT_APP_API}/quotes/send-initial-quote`, { requestPayload, quotePayload } )
            .then((res)=>{
                toast.success(res.data)
                clearForm()
            })
            .catch((e) => toast.error(e))
            .finally(() => setIsProcessing(false))
        }
    }

    const clearForm = () => {
        setArtistFee(0)
        setCar(0)
        setFood(0)
        setAccommodation(0)
        setVat(vatRateLow)
        setSelectedActId("")
        setMsg("")
    }

    const unRejectRequest = () => {
        // unarchive the request for this artist
        axios.post(`${process.env.REACT_APP_API}/requests/unarchive-request`, {
            artistId: currentUser.uid,
            requestId: request._id
        })
        .then((archiveResponse) => {
            toast.success(archiveResponse.data.message)
            setRequestRejected(false)

            if (request.artistId)
            {
                // if the unarchive succeeded and this is a direct request then inform the client
                axios.post(`${process.env.REACT_APP_API}/requests/inform-client-of-unrejected-request`, {
                    artistId: currentUser.uid,
                    selectedActId,
                    clientEmail: request.email,
                    requestId: request._id
                })
                .then((informClientResponse) => {
                    toast.success(informClientResponse.data.message)
                })
                .catch((informClientError) => {
                    toast.error(informClientError.data.error.message)
                })
            }
        })
        .catch((archiveError) => {
            toast.error(archiveError.data.error.message)
        })
    }

    const rejectRequest = () => {
        // archive the request for this artist with status rejected
        axios.post(`${process.env.REACT_APP_API}/requests/archive-request`, {
            artistId: currentUser.uid,
            requestId: request._id,
            archiveType: "Rejected"
        })
        .then((archiveResponse) => {
            toast.success(archiveResponse.data.message)
            setRequestRejected(true)

            if (request.artistId)
            {
                // if the archive succeeded and this is a direct request then inform the client
                axios.post(`${process.env.REACT_APP_API}/requests/inform-client-of-rejected-request`, {
                    artistId: currentUser.uid,
                    artistEmail,
                    artistName,
                    selectedActId,
                    clientId: request.clientId,
                    clientEmail: request.email,
                    clientName: request.name,
                    requestId: request._id
                })
                .then((informClientResponse) => {
                    toast.success(informClientResponse.data.message)
                })
                .catch((informClientError) => {
                    toast.error(informClientError.data.error.message)
                })
            }
        })
        .catch((archiveError) => {
            toast.error(archiveError.data.error.message)
        })
    }

    const noQuoteMade = () => {
        return !request.quoted?.includes(currentUser.uid);
    }


    // there's some complexity here:
    //   - if a quote has been made then display amend
    //   - if a quote has not been made then display create
    //   - if a quote has been paid then state that it is no longer changeable
    //   - if a request has been paid or if it was only ever directed to a single artist
    //     then the artist Id should be present in the request. If another artist manages
    //     to land here then safeguard by providing a message that the request is covered
    // additionally, concerning rejection:
    //   - only an unquoted request can be rejected
    //   - rejected requests can be unrejected
    return (
        <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-evenly', alignItems:'center', flexWrap:'wrap'}}>
            <div style={{display:'flex', flexDirection: 'column'}}>
                <h2>Wedding details</h2>
                <p>Client name - {request && request.name}</p>
                <p>Date of wedding - {moment(request.date).format('ddd, DD MMM YYYY')}</p>
                <p>Act requested - {request && request.info.actType}</p>
                <p>Start time - {request && request.info.startTime}</p>
                <p>End time - {request && request.info.endTime}</p>
                <p>Venue - {request && request.info.venue}</p>
                <p>Location - {request && request.info.location}</p>
                <p>Notes - {request.notes}</p>

                {noQuoteMade() ? (
                    requestRejected ? (
                        <div>
                            <p style={{ marginTop: '30px', fontSize: '14px' }}>You previously rejected this request.</p>
                            <Button
                                onClick={unRejectRequest}
                                variant='contained'
                                sx={{backgroundColor: '#ecbdba', width: '220px'}}
                            >
                                Unreject Request
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <p style={{ marginTop: '30px', fontSize: '14px' }}>You can also reject this request.</p>
                            <Button
                                onClick={rejectRequest}
                                variant='contained'
                                sx={{backgroundColor: '#ecbdba', width: '170px'}}
                            >
                                Reject Request
                            </Button>
                        </div>
                    )
                ) : null}
            </div>

            <div style={{width: 500, textAlign:'center', maxWidth:'95vw'}}>
                {(request.artistId && currentUser.uid !== request.artistId) ? (
                    <div>
                        <h2>This request has been accepted by another artist</h2>
                    </div>
                ) : (
                    <div>
                        {!hasPaid ? (
                            <>
                                <h2>{!quoteId ? 'Create' : 'Amend'} Quote</h2>
                            </>
                        ) : (
                            <>
                                <h2>Your quote has been accepted and paid</h2>
                                <p>(no further changes possible)</p>
                            </>
                        )}
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Artist fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={artistFee} onChange={(e)=>setArtistFee(e.target.value)}/>
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Mileage fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={car} onChange={(e)=>setCar(e.target.value)}/>
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Food fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={food} onChange={(e)=>setFood(e.target.value)}/>
                        <TextField sx={{m:1, width:'46%'}} disabled={hasPaid} label='Accommodation fee' size='small' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }} value={accommodation} onChange={(e)=>setAccommodation(e.target.value)}/>
                        <TextField sx={{m:1, width:'95%'}} size='small' label='Net total' value={total} disabled InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
                        <FormControl disabled={hasPaid} >
                            <FormLabel id="demo-row-radio-buttons-group-label">VAT</FormLabel>
                            <RadioGroup value={vat} onChange={(e)=>setVat(e.target.value)}
                                    row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value={vatRateLow} control={<Radio />} label="0%" />
                                <FormControlLabel value={vatRateHigh} control={<Radio />} label="20%" />
                            </RadioGroup>
                        </FormControl>
                        <TextField sx={{my:1}} fullWidth  label='Gross total' value={gross} disabled InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment> }}/>
                        <p>Clients will not see the breakdown of costs.</p>
                        <p>Our platform fee is passed on to the client ensuring the artist always receives what they deserve.</p>

                        {acts.length > 0 && (
                            <FormControl disabled={hasPaid || !!request.actName} fullWidth sx={{my:1}}>
                                <InputLabel id="demo-simple-select-label">Acts</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedActId}
                                    label="Acts"
                                    onChange={(e) => setSelectedActId(e.target.value)}
                                >
                                    {acts.map((act) => (
                                        <MenuItem key={act._id} value={act._id}>
                                            {act.actName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <TextField multiline rows={1} fullWidth sx={{my:1}} disabled={hasPaid} label='Message' value={msg} onChange={(e)=>setMsg(e.target.value)}/>
                        <Button
                            onClick={sendQuote}
                            variant='contained'
                            disabled={isProcessing || hasPaid || requestRejected}
                            sx={{ backgroundColor: '#ecbdba' }}
                            >
                            {!quoteId ? 'Send' : 'Update'}
                        </Button>
                    </div>)
                }
            </div>
        </div>
    )
}
