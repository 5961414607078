import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

const RequireAdminAuth = () => {
    const {currentUser, admin} = useAuth()
    let location = useLocation();

    return (currentUser && admin) ? <Outlet /> : <Navigate to="/artists" state={{from : location}} replace/>
};

export default RequireAdminAuth;