import React, { useEffect, useState} from 'react'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import Avatar from '@mui/material/Avatar';
import moment from 'moment'
import './Inbox.css'
import { useNavigate } from 'react-router-dom';

export default function ClientInbox() {
  let navigate = useNavigate()
  let { currentUser } = useAuth()
  const [chats, setChats] = useState([])

  useEffect(() => {
    getChats()
  },[])

  const getChats = () => {
    axios.post(`${process.env.REACT_APP_API}/chats/get-client-chats-by-id`, {id: currentUser.uid})
      .then((res) => {
        setChats(res.data)
      })
      .catch((e) => console.log(e))
  }

  return (
    <div id='inbox-page'>
      <div id='inbox-container'>
        {chats.length < 1 ? (
          <p style={{ textAlign: 'center' }}>Inbox empty</p>
        ) : (
          chats
            .slice() // Create a shallow copy of the array to avoid mutating the original
            .sort(function(a, b) {
              return new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded);
            })
            .map((chat, i) => {
              return (
                <div key={i} className='inbox-row' onClick={() => navigate(`/clients/inbox/${chat._id}`)}>
                  <div style={{ display: 'flex' }}>
                    <Avatar sx={{ width: 50, height: 50, mr: 3 }}>{chat.artistName.charAt(0)}</Avatar>
                    <p className='inbox-name' style={{ fontWeight: chat.clientRead ? 'normal' : 'bold' }}>{chat.artistName}</p>
                  </div>
                  <p style={{ textAlign: 'right' }}>{moment(chat.lastMsgAdded).fromNow()}</p>
                </div>
              );
            })
        )}
      </div>
    </div>
  )
}
