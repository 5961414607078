import React, { useState, useEffect} from 'react'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import './RequestList.css'
import { useAuth } from '../../Context/AuthContext'

export default function RequestList() {

    let { currentUser } = useAuth()

    let navigate = useNavigate()

    useEffect(() => {
        getRequests()
    }, [])

    const [requests, setRequests] = useState([])
    const [showArchived, setShowArchived] = useState(false);

    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-all-requests`, {
            artistId: currentUser.uid
        })
        .then((res) => {
            setRequests(res.data)
        })
        .catch((e) => console.log(e))
    }

    const deriveRequestStatus = (request) => {
        if (request.quoted.includes(currentUser.uid)) {
            return 'Quoted';
        }  else {
            return 'Not Quoted';
        }
    };

    const handleShowArchivedChange = () => {
        setShowArchived(!showArchived);
    };

    // Display all requests not fulfilled by another artist
    // Status indicates whether the given has been quoted or not
    return (
        <div id='request-list-page'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ flex: '1', marginRight: '10px' }}>Available wedding requests</h2>
                <label className="archived-checkbox-label">
                    <input
                        type="checkbox"
                        checked={showArchived}
                        onChange={handleShowArchivedChange}
                    />
                    <span className="tooltip">Show archived</span>
                 </label>
            </div>
            <table id='request-list-table'>
                <thead>
                    <tr id='thead-tr'>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Act Type</th>
                        <th>Start time</th>
                        <th>End time</th>
                        <th>Venue</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {requests.length < 1 ? (
                        <tr>
                            <td colSpan={6} style={{textAlign:'center'}}>
                                No requests available
                            </td>
                        </tr>
                    ) : (
                        requests
                            .filter((request) => {
                                if (showArchived) {
                                    return true;
                                }
                                // filter out requests with matching artistId in archived array
                                return !request.archived.some(
                                    (archivedItem) => archivedItem.artistId === currentUser.uid
                                );
                            })
                            .sort((a, b) => new Date(a.date) - new Date(b.date))
                            .map((request, i) => {
                                // Check if the request is archived
                                const isArchived = request.archived.some(
                                  (archivedItem) => archivedItem.artistId === currentUser.uid
                                );

                                // apply a CSS class based on the archived status
                                const rowClass = isArchived ? 'archived-entry' : '';

                                return (
                                    <tr
                                        key={i}
                                        className={rowClass}
                                        onClick={()=>navigate(`/artists/requests/${request._id}`)}
                                    >
                                        <td>{request.name}</td>
                                        <td>{moment(request.date).format('ddd, DD MMM YYYY')}</td>
                                        <td>{request.info.actType}</td>
                                        <td>{request.info.startTime}</td>
                                        <td>{request.info.endTime}</td>
                                        <td>{request.info.venue}</td>
                                        <td>{deriveRequestStatus(request)}</td>
                                    </tr>
                                );
                            })
                    )}
                </tbody>
            </table>
        </div>
    )
}
