import React, { useEffect, useState, useRef} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { UseInbox } from '../../Context/InboxContext'


// client side chat for creating messages from client to artist
export default function ClientChat() {

    let { getUnreadChats } = UseInbox()

    let { id } = useParams()
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState("")

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages]);

    useEffect(() => {
        getMessages()
        getChat()
    }, [])

    const getChat = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-client-chat-by-id`, {chatId: id})
        .then((res) => {
            setChat(res.data)
            console.log("Chat",res.data)
        })
        .catch((e) => console.log(e))
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: id})
        .then((res) => {
            setMessages(res.data)
            getUnreadChats()
        })
        .catch((e) => console.log(e))
    }

    const sendMessage = () => {
        if (!message) {
            return
        } else {
            axios.post(`${process.env.REACT_APP_API}/chats/send-client-message`, {
                chatId: id,
                artistEmail: chat.artistEmail,
                message,
                timeStamp: new Date(),
                sentBy: "Client"}
            )
            .then((res) => {
                setMessage("")
                getMessages()
                console.log(res.data)
            })
            .catch((e)=>console.log(e))
        }
    }

    return (
        <div id='chat-page'>
            {messages.map((msg, i) => {
                let lineBreaks = msg.message.split('-')
                return <div className={msg.sentBy === "Client" ? 'client-msg-container' : undefined}>
                    <div key={i} className={msg.sentBy === "Artist" ? 'artist-msg' : 'client-msg'}>
                        {lineBreaks.map((line, i) => {
                            const quoteRegex = /Quote ([a-fA-F0-9]{24}) (.*)/;
                            const actRegex = /For proposed act (.*?): (.*)/;
                            const genReqText = /Please try a general request/;
                            const quoteMatch = line.match(quoteRegex);
                            const actMatch = line.match(actRegex);
                            const genReqMatch = line.match(genReqText);
                            if (quoteMatch) {
                                const quoteId = quoteMatch[1];
                                const quoteURL = `/clients/quote/${quoteId}`;
                                return (
                                    <p key={i}>
                                        <a href={quoteURL} target="_blank" rel="noopener noreferrer">
                                            View Quote
                                        </a>
                                    </p>
                                )
                            } else if (actMatch) {
                                const prefix = line.split(actMatch[1])[0]
                                const actURL = `/find/artists/${actMatch[2]}`
                                return (
                                    <p key={i}>
                                        <span>{prefix}</span>
                                        <a href={actURL} target="_blank" rel="noopener noreferrer">
                                            {actMatch[1]}
                                        </a>
                                    </p>
                                )
                            } else if (genReqMatch) {
                                const prefix = line.split(genReqMatch[1])[0]
                                const findURL = `/find`
                                return (
                                    <p key={i}>
                                        <span>{prefix}</span>
                                        &nbsp;
                                        <a href={findURL} target="_blank" rel="noopener noreferrer">
                                            here
                                        </a>
                                    </p>
                                )
                            } else {
                                const myLines = String(line).split("\n");
                                return (
                                    <p key={i}>
                                        {myLines.map((line, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {line} <br />
                                                </React.Fragment>
                                            );
                                        })}
                                    </p>
                                )
                            }
                        })}

                        <p className='timestamp'>{moment(msg.timeStamp).format('HH:mm A, DD MMM')}</p>
                    </div>
                </div>
            })}

            <div id='message-container' ref={messagesEndRef}>
                <textarea id='msg-box' value={message} onChange={(e)=>setMessage(e.target.value)}/>
                <button id='send-btn' onClick={sendMessage}>Send to artist</button>
            </div>
        </div>
    )
}
