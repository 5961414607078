import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import './ClientUI.css'
import { signOut } from "firebase/auth";
import { auth } from '../../Firebase'
import Badge from '@mui/material/Badge';
import { UseInbox } from '../../Context/InboxContext';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../Assets/fdm.png'
import CloseIcon from '@mui/icons-material/Close';

export default function ClientUI() {

  let { unreadChats } = UseInbox()

  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div>
      <nav id='client-nav'>
          <Link to='/'>
            <img id='nav-logo' src={logo} alt="First Dance Music - Wedding music, bands and DJ's logo"/>
          </Link>

          <div id='nav-links'>
            <Link className='client-nav-link' to='/clients/wedding'>My Wedding</Link>
            <Badge badgeContent={unreadChats?.length}  color="error">
              <Link className='client-nav-link' to='/clients/inbox'>Inbox</Link>
            </Badge>
          </div>

          <button id='logout-btn' onClick={handleLogout}>Logout</button>
          <MenuIcon id='burger-menu' sx={{color:'white'}} onClick={() => setOpen(!open)}/>
      </nav>

      <div className={open ? 'side-nav-open' : 'side-nav-closed'}>
          <CloseIcon onClick={() => setOpen(!open)} sx={{color:'white'}}/>

          <Badge badgeContent={unreadChats?.length}  color="error">
            <Link className='client-nav-link' to='/clients/inbox' onClick={() => setOpen(!open)}>Inbox</Link>
          </Badge>

          <button id='logout-side-nav' onClick={() => {handleLogout(); setOpen(!open)}}>Logout</button>
      </div>

      <main >
        <Outlet />
      </main>
    </div>
  )
}
