import React, {useEffect, useState} from 'react'
import './Blogs.css'
import { Link } from 'react-router-dom'
import axios from 'axios'

export default function Blogs() {

  const [blogs, setBlogs] = useState([])

  useEffect(() => {
    getBlogs()
  }, [])

  const getBlogs = () => {
    axios
      .get(`${process.env.REACT_APP_API}/blogs/get-blogs`)
      .then((res) => {
        setBlogs(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div style={{padding: '1rem', minHeight: '90vh'}}>
        <h2 style={{fontFamily: 'Mont', marginLeft: '1rem'}}>Our Blogs</h2>
        <div style={{display:'flex', flexWrap:'wrap'}}>
          {blogs.map((blog, i) => {
            return  <Link to={`${blog._id}`} style={{margin: '1rem', borderRadius: 30}}>
                      <div className='blog-container' >
                          <img className='blog-container-img'  src={blog.headerImg} alt={blog.title}/>
                          <p className='blog-container-title'>{blog.title}</p>
                      </div>
                    </Link>
          })}
        </div>
      
       
    </div>
  )
}
