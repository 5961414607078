import React, { useState, useEffect} from 'react'
import axios from 'axios'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment'
import './BookingList.css'

export default function BookingList() {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [bookings, setBookings] = useState([])
    const [artists, setArtists] = useState([])
    const [selectedBooking, setSelectedBooking] = useState(null);

    useEffect(() => {
        getBookings()
        getArtists()
    }, [])

    const getBookings = () => {
        axios.get(`${process.env.REACT_APP_API}/bookings/get-all-bookings`)
        .then((res) => {
            setBookings(res.data)
        })
        .catch((e) => console.log(e))
    }

    const getArtists = () => {
        axios.get(`${process.env.REACT_APP_API}/users/get-artists`)
        .then((res) => {
            setArtists(res.data)
        })
        .catch((e) => console.log(e))
    }

    function getArtistName(artistId) {
        const artist = artists.find((artist) => artist._id === artistId);
        if (artist) {
            return `${artist.firstName} ${artist.lastName}`;
        } else {
            return 'Unknown Artist';
        }
    }

    const handleOpen = (booking) => {
        setSelectedBooking(booking);
        setOpen(true);
    };

    const formattedDate = (rawDate) => {
        const formattedDate = rawDate ? new Date(rawDate).toISOString().split('T')[0] : "";
        return formattedDate
    }

    return (
        <>
            <div id='booking-list-page'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2 style={{ flex: '1', marginRight: '10px' }}>All Bookings</h2>
                </div>
                <table id='booking-list-table'>
                    <thead>
                        <tr id='thead-tr'>
                            <th>Title</th>
                            <th>Booking Date</th>
                            <th>Artist Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.length < 1 ? (
                            <tr>
                                <td colSpan={3} style={{textAlign:'center'}}>
                                    No bookings as yet
                                </td>
                            </tr>
                        ) : (
                            bookings
                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                .map((booking, i) => {
                                    return (
                                        <tr key={i} onClick={() => handleOpen(booking)}>
                                            <td>{booking.title}</td>
                                            <td>{booking.createdAt ? moment(booking.createdAt).format('ddd, DD MMM YYYY'): ""}</td>
                                            <td>{getArtistName(booking.artistId)}</td>
                                        </tr>
                                    );
                                })
                        )}
                    </tbody>
                </table>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus={true}
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ my: 2, textAlign: 'center' }}
                    >
                        Booking Details
                    </Typography>

                    <TextField
                        fullWidth
                        sx={{my:1}}
                        label="Wedding Title (client name - band type)"
                        value={selectedBooking?.title}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        fullWidth
                        sx={{my:2}}
                        label="Date of booking"
                        type="date"
                        value={formattedDate(selectedBooking?.createdAt)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        fullWidth
                        sx={{my:2}}
                        label="Date of wedding"
                        type="date"
                        value={selectedBooking?.date}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        fullWidth
                        sx={{my:2}}
                        label="Stripe Customer Id"
                        value={selectedBooking?.stripeCustomerId}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        fullWidth
                        sx={{my:2}}
                        label="Client Name/Email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        fullWidth
                        sx={{my:2}}
                        label="Venue Address"
                        multiline rows={5}
                        value={selectedBooking?.venueAddress}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Button
                        variant="outlined"
                        sx={{ mx: 'auto', mt: 2, display: 'block' }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxWidth: '95vw',
    maxHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };