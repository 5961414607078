import React from 'react';
import "./Navbar.css";
import SendIcon from '@mui/icons-material/Send';
import { Link, useLocation } from 'react-router-dom'
import logo from '../../Assets/fdm.png'
import pinkLogo from '../../Assets/pinkLogo.webp'
import pinkLogoLong from '../../Assets/long_pink.webp'
import DropDownMenu from '../DropDownMenu';

export default function Navbar() {

    const location = useLocation()

    
  
    return (
        <div id={location.pathname === '/' ? 'inverted-navbar' : 'navbar'}>
            <nav>
                <Link to="/">
                    <img  
                        style={{height: 30, marginTop:15, maxWidth: '65vw', objectFit: 'contain'}} 
                        src={ location.pathname === '/' ? pinkLogoLong : logo }  
                        alt='First Dance Music logo to find bands and singers for wedding North West'
                    />
                </Link>
                <Link to="/find/artists" className='main-nav'>Our Artists</Link>

                <Link to='/find' id='post-wedding-nav' >
                    <SendIcon sx={{color:'#fff'}} fontSize="small"/>
                    <p className='find-nav-link'>Post wedding request</p>
                </Link>
            </nav>

            <DropDownMenu />

            </div>
    )
}

