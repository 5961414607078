import React, { useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import moment from 'moment'
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import ViewBooking from './ViewBooking';
import { useNavigate } from 'react-router-dom'
import './Calendar.css';

export default function Calendar() {

  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let { currentUser } = useAuth()
  let navigate = useNavigate()
  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")
  const [notes, setNotes] = useState("")
  const [bookings, setBookings] = useState([])
  const [requests, setRequests] = useState([])
  const [quotes, setQuotes] = useState([])
  const [event, setEvent] = useState("")

  useEffect(() => {
    getBookings()
    getRequests()
    getQuotesForArtist()
  }, [])

  const getBookings = () => {
    axios.post(`${process.env.REACT_APP_API}/bookings/get-bookings-by-id`, {id: currentUser.uid})
    .then((res)=>{
      setBookings(res.data)
    })
    .catch((e) => console.log(e))
  }

  const getRequests = () => {
    axios.post(`${process.env.REACT_APP_API}/requests/get-all-requests`, {
      artistId: currentUser.uid
    })
    .then((res) => {
      setRequests(res.data)
      console.log("requests", res.data)
    })
    .catch((e) => console.log(e))
  }

  const getQuotesForArtist = () => {
    axios.post(`${process.env.REACT_APP_API}/quotes/get-quotes-for-artist`, {artistId: currentUser._id})
    .then((res) => {
      setQuotes(res.data)
    })
    .catch((e) => console.log(e))
  }

  const createBooking = () => {
    if (!title || !date) {
      toast.error("Please add a title and date")
    } else {
      let payload = {title, artistId: currentUser.uid, date: moment(date).format("YYYY-MM-DD"), notes}
      console.log(payload)
      axios.post(`${process.env.REACT_APP_API}/bookings/create-new-booking`, payload)
        .then(() => {
          toast.success("Successfully added")
          handleClose()
          getBookings()
          setTitle(""); setDate(""); setNotes("")
        })
        .catch((e) => console.log(e))
    }
  }

  function renderEventContent(eventInfo) {
    // an id implies the booking came from a request and was auto generated
    return eventInfo.event.extendedProps?.requestId ? 'auto-booking' : 'manual-booking';
  }

  return (
    <div style={{padding: '2rem', maxWidth: 1200, margin:'0 auto', textAlign:'center'}}>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom: 20, flexWrap:'wrap'}}>
        <button className='fdm-btn' onClick={handleOpen}>
          Add event
        </button>

        <div style={{display:'flex', alignItems:'center', flexWrap:'wrap'}}>
          <div className='legend'>
            <div className='legend-color' style={{backgroundColor: '#7eb37a'}}></div>
            <p>- Booked event</p>
          </div>

          <div className='legend'>
            <div className='legend-color' style={{backgroundColor: '#A7C7E7'}}></div>
            <p>- Manually added event</p>
          </div>

          <div className='legend'>
            <div className='legend-color' style={{backgroundColor: '#F1C167'}}></div>
            <p>- Quoted Request</p>
          </div>

          <div className='legend'>
            <div className='legend-color' style={{backgroundColor: '#ecbdba'}}></div>
            <p>- Unquoted Request</p>
          </div>
        </div>
      </div>

      <FullCalendar
        plugins={[ dayGridPlugin ]}
        eventClassNames={renderEventContent}
        initialView="dayGridMonth"
        eventSources={[
          {
            // uses class names for colour specific event rendering
            events: bookings,
          },
          {
            // display requests unless they have a booking already
            // in that case the booking itself will be displayed as above
            // furthermore any request that has a quote should be coloured as such
            // using mapping rather than class names to over-ride stored properties
            events: requests
            .filter(event => !event.quoteId)
            .map((request) => {
              const hasQuote = quotes.some((quote) => quote.requestId === request._id);

              return {
                ...request,
                ...(hasQuote && { backgroundColor: '#f1c167', borderColor: '#f1c167' }),
              }
            }),
          },
        ]}
        eventClick={function(info) {
          if (info.event.title === "Request") {
            navigate(`/artists/requests/${info.event.extendedProps._id}`)
          } else {
            setEvent(info.event)
            setView(true)
          }
        }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            New Booking
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            Manually add your bookings outside of First Dance Music to help you view and manage your availability easily.
          </Typography>

          <TextField
            fullWidth
            sx={{my:1}}
            label="Booking Title eg. Wedding"
            value={title}
            onChange={(e)=>setTitle(e.target.value)}/>

          <TextField
            fullWidth
            sx={{my:1}}
            label="Date of booking"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}/>

          <TextField
            fullWidth
            sx={{my:1}}
            label="Notes"
            multiline rows={2}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}/>

          <Button
            variant='contained'
            sx={{backgroundColor: '#ecbdba', mt:2}}
            onClick={createBooking}>
            Create Booking
          </Button>

          <Button
            variant='outlined'
            sx={{ml:1, mt:2}}
            onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Modal>

      <ViewBooking event={event} view={view} setView={setView}/>
    </div>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth:'95vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '80vh',
  overflow: 'scroll'
};