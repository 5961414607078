import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { useAuth } from './AuthContext';

const InboxContext = createContext("");

export function UseInbox() {
    return useContext(InboxContext)
}

export function InboxProvider({children}) {

    const {currentUser, role} = useAuth()

    const [unreadChats, setUnreadChats] = useState([])

    useEffect(() => {
        if (currentUser) {
            getUnreadChats()
        } else {
            // handle case when user logs out or currentUser becomes null
            setUnreadChats([]);
        }
    }, [currentUser]);

    const getUnreadChats = () => {
        let chatEndpoint;
        if (role === 'client') {
            chatEndpoint = 'get-unread-client-chats';
        } else { // otherwise is artist
            chatEndpoint = 'get-unread-artist-chats';
        }

        axios.post(`${process.env.REACT_APP_API}/chats/${chatEndpoint}`, {id: currentUser.uid})
        .then(function(res) {
            setUnreadChats(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const value = {
        unreadChats, getUnreadChats
    }

    return (
        <InboxContext.Provider value={value}>
            {children}
        </InboxContext.Provider>
    )
}
