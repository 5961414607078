import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import React, { useRef, useEffect, useState } from "react";
import Button from '@mui/material/Button'
import { TextField } from '@mui/material';
import axios from 'axios'
import './PaymentForm.css'
import { toast } from 'react-toastify';
import stripeLogo from '../../Assets/stripe.png'

function PaymentForm(props) {
    const { request, customerGross, quote, onSuccess } = props;
    const monthThreshold = 3
    const amountThreshold = 500
    const currency = "GBP"

    const stripe = useStripe()
    const elements = useElements()

    const [cardName, setCardName ] = useState(request.name)
    const [cardEmail, setCardEmail ] = useState(request.email)
    const [paymentError, setPaymentError] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessingInstalment, setIsProcessingInstalment] = useState(false);

    // Create a ref for the payment form container div
    const formRef = useRef(null);

    // scroll to the payment form element when it's displayed
    useEffect(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, []);

    const calculateMonthsDifference = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        // determine total span of months
        let months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

        // compensate for partial month so payments are complete at least 30 days before
        if (end.getDate() < start.getDate()) {
            months--;
        }

        return months;
    };

    // Calculate the number of instalments based on the number of months between
    // now and request.date. Instalments must be complete one full month before
    // event date. Initial payment is the deposit, instalments start 1 month later
    // and must finish at least 1 month before
    const dateNow = new Date()
    const differenceInMonths = calculateMonthsDifference(dateNow, request.date)
    const numberOfMonthlyInstalments = differenceInMonths - 1

    // Calculate the instalment amounts
    // note that this truncates digits, the total will therefore be up to 1p less per instalment
    const firstInstalment = (customerGross * 0.1).toFixed(2);;
    const monthlyInstalment = ((customerGross - firstInstalment) / numberOfMonthlyInstalments).toFixed(2);


    const handlePaymentSubmit = (stripeCustomerId, paymentSummary) => {
        // successful payment - return to parent
        const paymentData = {
            message: 'Payment Successfully Submitted',
            stripeCustomerId,
            paymentSummary
        }

        // Call the onSuccess function passed from the parent
        onSuccess(paymentData);
    };

    const payByInstalments = async () => {
        setIsProcessingInstalment(true);
        setPaymentError("");

        try {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: cardEmail,
                },
            });

            if (result.error) {
                toast.error("There was a problem making the payment - " + result.error.message);
                console.error('Error creating payment method:', result.error.message);
            } else {
                // create the payment intent for the deposit and then a subscription schedule
                const response = await axios.post(`${process.env.REACT_APP_API}/payments/create-subscription-with-deposit`, {
                    payment_method: result.paymentMethod.id,
                    deposit: firstInstalment,
                    monthlyInstalment,
                    currency,
                    numberOfMonthlyInstalments,
                    dateNow,
                    email: cardEmail,
                    name: cardName,
                    quoteId: quote._id
                });

                const { client_secret, stripeCustomerId, message, paymentSummary } = response.data;

                // confirm the payment using the payment intent secret
                // any of the three elements can be used
                const paymentResult = await stripe.confirmCardPayment(client_secret, {
                    payment_method: {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: cardName,
                            email: cardEmail
                        },
                    }
                })

                if (paymentResult.error) {
                    toast.error("There was a problem making the payment. ", paymentResult.error.message);
                    console.error('Error creating payment:', paymentResult.error.message);
                } else {
                    console.log(message);

                    // and we are done with the payment form
                    handlePaymentSubmit(stripeCustomerId, paymentSummary);
                }

                setIsProcessingInstalment(false);
            }
        } catch (error) {
            // handle specific responses
            console.log("error:", error)
            if (error.response && error.response.status === 402) {
                // Handle payment failure scenario here
                console.log("Payment failed: ", error.response.data.error);
                toast.error("There was a problem making the payment. " + error.response.data.error);
                setPaymentError(error.response.data.error);
            } else {
                setPaymentError(error.message);
                console.error('Error setting up payment scheme:', error);
            }
            setIsProcessingInstalment(false);
        }
    }

    const makePayment = async () => {
        setIsProcessing(true);
        setPaymentError("");

        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/payments/create-payment-intent`, {
                customerGross,
                currency,
                email: cardEmail,
                name: cardName,
                quoteId: quote._id
            });

            const { client_secret, stripeCustomerId, message, paymentSummary } = response.data;

            // confirm the payment using the payment intent secret
            // any of the three elements can be used
            const result = await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: cardName,
                        email: cardEmail
                    },
                },
            })

            if (result.error) {
                toast.error("There was a problem making the payment. " + result.error.message);
                console.error('Error creating payment:', result.error.message);
            } else {
                console.log(message);

                // and we are done with the payment form
                handlePaymentSubmit(stripeCustomerId, paymentSummary);
            }

            setIsProcessing(false);

        } catch (error) {
            setPaymentError(error.message);
            console.error('Error creating payment:', error);
            setIsProcessing(false);
        }
    };


    return (
        <div>
            <div style={{ height: '20px' }} />
            <div
                style={{
                    background: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '10px',
                    maxWidth: '50%',
                    margin: '0 auto'
                }}
            >
                <div ref={formRef}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ alignItems: 'center' }}>
                            <h2 style={{ textAlign: 'center' }}>Payment Details</h2>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    sx={{m:'10px', width:'46%'}}
                                    label='Name on card'
                                    value={cardName}
                                    size='small'
                                    onChange={(e) => setCardName(e.target.value)}
                                />
                                <TextField
                                    sx={{m:'5px', width:'46%'}}
                                    disabled
                                    label='Email'
                                    value={cardEmail}
                                    size='small'
                                    onChange={(e) => setCardEmail(e.target.value)}
                                />
                            </div>
                            <div className="card-element-container">
                                <div className="card-element full-width">
                                    <CardNumberElement />
                                </div>
                                <div className="card-element narrow-width">
                                    <CardExpiryElement />
                                </div>
                                <div className="card-element narrow-width">
                                    <CardCvcElement />
                                </div>
                                <div className="stripe-attribution">
                                    <a
                                        href="https://stripe.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    <img src={stripeLogo} alt="Stripe logo"/>
                                    </a>
                                </div>
                                {paymentError ? (
                                    <div role="alert" style={{ paddingTop: '15px', color: 'red' }}>
                                        {paymentError}
                                    </div>
                                ) : null}
                            </div>
                            {(differenceInMonths > monthThreshold && customerGross > amountThreshold) ? (
                                <p style={{ textAlign: 'center' }}>
                                    You can clear your balance upfront or pay by monthly instalments over the period until your wedding at 0% interest.
                                </p>
                                ) : null}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {(differenceInMonths > monthThreshold && customerGross > amountThreshold) ? (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 40px'  }}>
                                    <p className="smallParagraph">Initial 10% depost now of £{firstInstalment}</p>
                                    <p className="smallParagraph">Followed by {numberOfMonthlyInstalments} monthly amounts of £{monthlyInstalment}</p>
                                    <p className="smallParagraph">Total to pay £{customerGross}</p>
                                    <button id="payment-btn"
                                        disabled={isProcessingInstalment}
                                        onClick={payByInstalments}>
                                        {isProcessingInstalment ? "Submitting..." : "Pay by Instalments"}
                                    </button>
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 40px' }}>
                                <br/><br/><br/>
                                <p className="smallParagraph">Pay full amount now</p>

                                <p className="smallParagraph">Total to pay £{customerGross}</p>
                                    <button id="payment-btn"
                                        disabled={isProcessing}
                                        onClick={makePayment}>
                                        {isProcessing ? "Submitting..." : "Pay in Full"}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p className="smallParagraph">
                                    Amount to pay £{customerGross}
                                </p>
                                <div style={{textAlign: 'center'}}>
                                    <button id="payment-btn"
                                        disabled={isProcessing}
                                        onClick={makePayment}>
                                        {isProcessing ? "Submitting..." : "Make Payment"}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ height: '20px' }} />
            </div>
            <div style={{ height: '20px' }} />
        </div>
    )
}

export default PaymentForm;
