//icons
import solo from './Assets/Icons/solo.png'
import duo from './Assets/Icons/duo.png'
import band from './Assets/Icons/band.png'
import other from './Assets/Icons/other.png'
import dj from './Assets/Icons/dj.png'
import sax from './Assets/Icons/sax.png'
import piano from './Assets/Icons/piano.png'

export const ActTypes = [
    {name: 'Solo', icon: solo },
    {name: 'Duo', icon: duo },
    {name: 'Band', icon: band },
    {name: 'Sax', icon: sax },
    {name: 'DJ', icon: dj },
    {name: 'Piano', icon: piano },
    {name: 'Other', icon: other },
];

export const Locations = [
    'North West',
    'North East',
    'Yorkshire',
    'West Midlands',
    'East Midlands',
    'East of England',
    'South West',
    'South East',
    'London'
];
