import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import './ArtistUI.css'
import {  signOut } from "firebase/auth";
import { auth } from '../../Firebase'
import Badge from '@mui/material/Badge';
import { UseInbox } from '../../Context/InboxContext';
import { useAuth } from '../../Context/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../Assets/fdm.png'
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ArtistUI() {

  let { unreadChats } = UseInbox()
  let { admin } = useAuth()

  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }


  return (
    <div>
      <nav id='artist-nav'>
        <Link to='/'>
          <img id='nav-logo' src={logo} alt="First Dance Music - Wedding music, bands and DJ's logo"/>
        </Link>

        <div id='nav-links'>
          <Link className='artist-nav-link' to='/artists'>Calendar</Link>
          <Link className='artist-nav-link' to='/artists/acts'>Acts</Link>

          {admin && (
            <>
              <div
                className={`artist-nav-link admin`}
                onClick={() => {setAdminDropdownOpen(!adminDropdownOpen);}}
              >
                ADMIN <ExpandMoreIcon className={`expand-icon ${adminDropdownOpen ? 'expanded' : ''}`} />
              </div>

              {adminDropdownOpen && (
                <div className={`admin-dropdown active`}>
                  <Link className='artist-nav-link' to='/artists/list'>
                    Artists
                  </Link>
                  <Link className='artist-nav-link' to='/artists/act-list'>
                    Admin Acts
                  </Link>
                  <Link className='artist-nav-link' to='/artists/bookings'>
                    Bookings
                  </Link>
                  <Link className='artist-nav-link' to='/artists/system'>
                    System
                  </Link>
                </div>
              )}
            </>
          )}

          <Link className='artist-nav-link' to='/artists/requests'>Requests</Link>
          <Badge badgeContent={unreadChats?.length}  color="error">
            <Link className='artist-nav-link' to='/artists/inbox'>Inbox</Link>
          </Badge>
        </div>

        <button className='primary-btn' onClick={handleLogout}>Logout</button>
        <MenuIcon id='burger-menu' sx={{color:'white'}} onClick={() => setOpen(!open)}/>
      </nav>

      <div className={open ? 'side-nav-open' : 'side-nav-closed'}>
        <CloseIcon onClick={() => setOpen(!open)} sx={{color:'white'}}/>

        <Link className='artist-nav-link' to='/artists' onClick={() => setOpen(!open)}>Calendar</Link>
        <Link className='artist-nav-link' to='/artists/acts' onClick={() => setOpen(!open)}>Acts</Link>

        {admin && (
          <>
            <div
              className={`burger-artist-nav-link admin`}
              onClick={() => {setAdminDropdownOpen(!adminDropdownOpen);}}
            >
              Admin <ExpandMoreIcon className={`expand-icon ${adminDropdownOpen ? 'expanded' : ''}`} />
            </div>

            {adminDropdownOpen && (
              <div >
                <Link className='artist-nav-link' to='/artists/list'>
                  Artists
                </Link>
                <Link className='artist-nav-link' to='/artists/act-list'>
                  Admin Acts
                </Link>
                <Link className='artist-nav-link' to='/artists/bookings'>
                  Bookings
                </Link>
                <Link className='artist-nav-link' to='/artists/system'>
                  System
                </Link>
              </div>
            )}
          </>
        )}

        <Link className='artist-nav-link' to='/artists/requests' onClick={() => setOpen(!open)}>Requests</Link>
        <Badge badgeContent={unreadChats?.length}  color="error">
          <Link className='artist-nav-link' to='/artists/inbox' onClick={() => setOpen(!open)}>Inbox</Link>
        </Badge>

        <button id='logout-side-nav' onClick={() => {handleLogout(); setOpen(!open)}}>Logout</button>
      </div>

      <main >
        <Outlet />
      </main>
    </div>
  )
}

