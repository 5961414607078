import React from 'react'

export default function ClientTerms() {
  return (
    <div style={{padding:'2rem', width: 1200, maxWidth: '90vw', margin: '0 auto'}}>
        <h1 style={{fontFamily:'Mont', color: '#1e1e1e'}}>Terms and Conditions for First Dance Music</h1>

        <p><b>Last Updated:</b> September 12, 2023</p>

<p>Please read these Terms and Conditions ("Terms") carefully before using the First Dance Music web and/or mobile application (the "Service"). These Terms apply to all users of the Service, including brides, grooms, artists (referred to as "Suppliers"), and anyone else accessing or using the Service.</p>

<p>By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, do not use the Service.</p>

<h3 style={{fontFamily:"Mont"}}>1. Definitions</h3>

<p><b>Service:</b> The web application known as "First Dance Music," which allows users to find, book and pay for live music and DJs for weddings and related events.</p>

<p><b>User:</b> Any individual or entity accessing or using the Service, including brides, grooms, artists (Suppliers), and any other parties involved.</p>

<h3 style={{fontFamily:"Mont"}}>2. Booking and Payment</h3>

<p><b>2.1 Booking:</b> Users can book Suppliers through the Service by selecting a Supplier, specifying event details, and submitting a booking request. Once a booking is confirmed by the Supplier, a binding contract is formed between the user and the Supplier.</p>

<p><b>2.2 Payment Options:</b> Users can choose one of the following payment options:</p>

<p><b>a) Upfront Payment:</b> Users may pay the full booking fee upfront at the time of booking.</p>

<p><b>b) Installments:</b> Users may opt for installment payments, with the final installment due no later than 30 days prior to the wedding date.</p>

<p><b>2.3 Payment Responsibility:</b> It is the user's responsibility to ensure timely and complete payment according to their chosen payment option.</p>

<h3 style={{fontFamily:"Mont"}}>3. Cancellation and Refunds</h3>

<p><b>3.1 Cancellation by User:</b></p>

<p><b>3.1.1 Deposit Payment:</b> The deposit payment made by the user is always non-refundable, regardless of the cancellation date.</p>

<p><b>3.1.2 Cancellation Over 12 Months Before the Wedding Date:</b> If the user cancels the booking over 12 months before the wedding date, they will receive a full refund of the amount paid for the artist's services, minus the non-refundable deposit.</p>

<p><b>3.1.3 Cancellation Between 6 and 12 Months Before the Wedding Date:</b> If the user cancels the booking between 6 and 12 months before the wedding date, they will be eligible for a 75% refund of the amount paid for the artist's services, minus the non-refundable deposit.</p>

<p><b>3.1.4 Cancellation Between 3 and 6 Months Before the Wedding Date:</b> If the user cancels the booking between 3 and 6 months before the wedding date, they will receive a 50% refund of the amount paid for the artist's services, minus the non-refundable deposit.</p>

<p><b>3.1.5 Cancellation Less Than 3 Months Before the Wedding Date:</b> If the user cancels the booking less than 3 months before the wedding date, no refund will be provided, and the full amount paid for the artist's services, minus the non-refundable deposit, will be forfeited.</p>

<p><b>3.2 Supplier Cancellation:</b></p>

<p><b>3.2.1</b> The Supplier is not permitted to cancel the booking for any reason.</p>

<p><b>3.2.2</b> If the Supplier does cancel for any reason, the Client may pursue legal action against the Supplier.</p>

<p><b>3.2.2.1</b> If the Supplier cancels for any reason they will pay the Agent a Penalty Fee equal to any Deposit received by the Agent.</p>

<p><b>3.3</b> In the event of the Supplier cancelling, the Agent will use best endeavors to find the Client a suitable replacement Supplier at a similar Supplier Fee. If this is successful and the Client agrees to the replacement Supplier, the Agent would transfer the Deposit already paid to the new booking. If another Supplier cannot be found, despite the Agent's best endeavors, then the Agent will pay the Client any Penalty Fee it receives from the Supplier.</p>

<p><b>3.4</b> If payment of Cancellation and Penalty Fees set out above are not made by the Supplier within 14 days of the cancellation, the debt may be passed to a Debt Recovery Firm which may incur additional costs for the Supplier.</p>

<h3 style={{fontFamily:"Mont"}}>4. Service Fees</h3>

<p><b>4.1 User Fees:</b> First Dance Music will charge a service fee to users for using the Service. The amount and payment terms will all be included in the direct quote and payment to the Supplier via the platform.</p>

<p><b>4.2 Supplier Fees:</b> First Dance Music will not charge Suppliers a service fee for listing their services on the platform.</p>

<h3 style={{fontFamily:"Mont"}}>5. Dispute Resolution</h3>

<p>In the event of any dispute between a user and a Supplier, both parties agree to make a good-faith effort to resolve the issue amicably. First Dance Music may, at its discretion, provide assistance in resolving disputes, but it is not liable for disputes between users and Suppliers.</p>

<h3 style={{fontFamily:"Mont"}}>6. Privacy Policy</h3>

<p>All personal information provided by users and Suppliers is subject to our Privacy Policy, which can be found on the First Dance Music website.</p>

<h3 style={{fontFamily:"Mont"}}>7. Changes to Terms</h3>

<p>First Dance Music reserves the right to modify or revise these Terms at any time. Users will be notified of any changes, and continued use of the Service after such modifications constitutes acceptance of the revised Terms.</p>

<h3 style={{fontFamily:"Mont"}}>8. Contact Information</h3>

<p>If you have any questions or concerns about these Terms, please contact us at info@firstdancemusic.co.uk.</p>

<p>By using the First Dance Music Service, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
    </div>
  )
}
