import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import close from '../../../Assets/Icons/close.png'
import { Locations } from '../../../Constants';
import moment from 'moment'
import { SplitName } from '../../../Utilities.js'

export default function ModalEnquire( { setOpen, act } ) {

      // modal state
  const [name, setName ] = useState("")
  const [email, setEmail ] = useState("")
  const [date, setDate ] = useState("")
  const [startTime, setStartTime ] = useState("")
  const [endTime, setEndTime ] = useState("")
  const [venue, setVenue ] = useState("")
  const [location, setLocation ] = useState("")
  const [notes, setNotes ] = useState("")
  const [loading, setLoading ] = useState(false)
  const [sent, setSent ] = useState(false)

  const handleContainerClick = (e) => {
    e.stopPropagation(); // Prevents click event from bubbling up to the modal div
  };

 // create client account, save the quote, inform artists
 const handleEnquire = () => {
    if(moment(date).isBefore(moment())){
      toast.error("Please add a date in the future")
    } else if (!name || !email || !date || !endTime || !startTime) {
      toast.error("Please add all required fields")
    } else {
      setLoading(true)

      const { firstName, lastName } = SplitName(name);

      axios.post(`${process.env.REACT_APP_API}/users/create-new-client`, {
        firstName,
        lastName,
        email,
        img:"https://caretestresources.s3.eu-west-2.amazonaws.com/avatar.png"
      })
      .then((userResponse) => {
        let payload = {
          title: "Request",
          date: moment(date).format("YYYY-MM-DD"),
          name,
          email,
          clientId: userResponse.data.clientId,
          info: {actType: act.category, startTime, endTime, venue, location},
          openRequest: false,
          artistId: act.artistId,
          actName: act._id,
          notes, backgroundColor:'#ecbdba',
          borderColor:'#ecbdba',
          textColor:'#fff'
        }

        axios.post(`${process.env.REACT_APP_API}/requests/create-new-request`, payload)
        .then(() => {
          if (userResponse.status === 201) {
            toast.success("Successfully sent request and created account")
          } else { // should be 200
            toast.success("Successfully sent request")
          }

          setOpen(false)
          setSent(true); setLoading(false)
          setName("");setDate("");setEmail("");setNotes("");setStartTime("");setVenue("");setLocation("");setEndTime("")
        })
        .catch((error) => {
          toast.error("Error submitting request");
        })
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          // display a specific server error message
          toast.error(error.response.data.message);
        } else {
          // handle other errors
          toast.error("Error submitting request");
        }

        setLoading(false)
      })
    }
  }


  return (
    <div className='modal' onClick={(e) =>  setOpen(false)}>

    <div className='modal-container' onClick={handleContainerClick}>
      <img className='hover close-modal-btn' src={close} alt='Close Om Yoga share box' onClick={() => setOpen(false)}/>

      <h3 className='modal-header'>Request Quote</h3>
        
        <label style={{marginBottom: 5}}>Wedding date</label>
        <input 
            className='single-input'
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
        />

        <div className='vertical-spacer' />

        <input 
            className='top-input' 
            type='text' 
            placeholder='Full name' 
            value={name}
            onChange={(e) => setName(e.target.value) }
        />
        <input 
            className='bottom-input' 
            type='text' 
            placeholder='Email address' 
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
        />

        <div className='vertical-spacer' />

        <div style={{display:'flex', justifyContent:'space-between'}}>
            <label>Start time (estimate)</label>
            <label style={{marginLeft: 10}}>End time (estimate)</label>
        </div>
        
        <div style={{width: '100%', display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <input className='left-input'  type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />

            <input className='right-input' type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
        </div>

        <div className='vertical-spacer' />

        <input 
            className='top-input'
            type="text"
            value={venue} 
            onChange={(e)=>setVenue(e.target.value)}
            placeholder='Venue name'
        />
      
        <select 
            className='bottom-input'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
        >
            <option value="">
                <em>Select location</em>
            </option>
            {Locations.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
                ))
            }
        </select>

        <div className='vertical-spacer' />

       
        <textarea 
        style={{resize: 'none', height: 200, padding: 10}}
            className='single-input'
            placeholder='Notes e.g. drinks reception, first dance, start times etc'
            value={notes}
            onChange={(e)=>setNotes(e.target.value)}
        />


        <div className='vertical-spacer' />

        


      <button className='modal-btn' onClick={handleEnquire} disabled={loading}>
        {loading ? 'Requesting...' : "Request quote"}
      </button>


    </div>
    
</div>
  )
}
