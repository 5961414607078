import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ProfileThumbnail.css';
import { ExtractEmbedFromVideoUrl } from '../../../Utilities.js'
import YouTube from 'react-youtube';

export default function ProfileThumbnail( {artist, ...props} ) {

    const [showVid, setShowVid] = useState(false);

    const opts = {
        width: '100%',
        height: '100%',
        playerVars: {
            controls: 1,
            autoplay: 1,
            modestbranding: 1,
            rel: 0,
            fs: 1,
          }
    };

    function changeToVid(){
        setShowVid(true)
    }

    return (
        <div id='thumbnail' {...props}>
            {showVid ?
                (<>
                    <YouTube
                    videoId={artist.video && ExtractEmbedFromVideoUrl(artist.video)}
                    opts={opts}
                    onPlay={(e) => e.target.setPlaybackQuality('small')}
                    className='thumb-media'
                />
                <Link className='view-profile-btn' to={`/find/artists/${artist._id}`}>View Profile</Link>
                </>
            ):(
                <img onClick={changeToVid} className='thumb-media' src={artist.primaryImg} />
            )}
        </div>
    )
}
