import React, { useState, useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Modal from '@mui/material/Modal';
import { useAuth } from '../../../Context/AuthContext';
import { toast } from 'react-toastify'
import Switch from '@mui/material/Switch';
import './ActList.css'
import { ExtractEmbedFromVideoUrl } from '../../../Utilities.js'
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate} from 'react-router-dom'
import YouTube from 'react-youtube';

export default function ActList() {

    const [acts, setActs] = useState([])
    const [open, setOpen] = useState(false);
    const [actData, setActData] = useState(null);
    let navigate = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(()=>{
        getActs()
    }, [])

    const getActs = () =>{
        axios.get(`${process.env.REACT_APP_API}/acts/get-all-acts`)
        .then((res)=>{
          setActs(res.data)
          console.log("Acts", res.data)
        })
        .catch((e)=>console.log(e))
    }

    const handlePublish = (actId, checked) =>{
        axios.post(`${process.env.REACT_APP_API}/acts/publish-act`, {actId, checked})
        .then((res)=>{
          toast.success(res.data)
          getActs()
        })
        .catch((e)=>console.log(e))
    }

    const handleFeatured = (actId, checked) =>{
        axios.post(`${process.env.REACT_APP_API}/acts/feature-act`, {actId, checked})
        .then((res)=>{
          toast.success("Updated")
          getActs()
        })
        .catch((e)=>console.log(e))
    }

    const opts = {
        width: '100%',
        height: '200',
        playerVars: {
            controls: 1,
            modestbranding: 1,
            rel: 0,
            fs: 1,
        },
    };


    return (
        <div>
          <h1>Acts</h1>

          <table id='act-list-table' style={{width: 800, maxWidth:'90vw', margin: '1rem auto'}}>
              <thead>
                <tr style={{border:'1px solid grey'}}>
                  <th></th>
                  <th>Name</th>
                  <th>Video</th>
                  <th>Published</th>
                  <th>Featured</th>
                  <th></th>

                </tr>
              </thead>
              <tbody>

          {acts.length > 0 ?

          acts.map((act, i)=>{
                return <tr key={i} id='act-table-row' onClick={() => { handleOpen(); setActData(act) }}>
                  <td><img className='admin-act-img' alt={act.actName} src={act.primaryImg}/></td>
                  <td>{act.actName}</td>
                  <td>{act.video && <YouTubeIcon sx={{color:'red'}}/>}</td>
                  <td onClick={(e) => e.stopPropagation()}>
                      <Switch  checked={act.published} color="success" onChange={(e)=>{handlePublish(act._id, e.target.checked)}} />
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                      <Switch  checked={act.featured} color="success" onChange={(e)=>{handleFeatured(act._id, e.target.checked)}} />
                  </td>
                  <td onClick={(e) =>{ e.stopPropagation();navigate(`/artists/acts/${act._id}`) }}>
                    <EditIcon />
                  </td>

                </tr>
        })
          :
          <tr>
              <td>No acts available</td>
          </tr>
          }

          </tbody>

      </table>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
              {actData &&
              <div>
                  <h3>{actData.actName}</h3>

                  {actData.video &&
                      <YouTube
                          videoId={ExtractEmbedFromVideoUrl(actData.video)}
                          opts={opts}
                          onPlay={(e) => e.target.setPlaybackQuality('small')}
                      />
                  }

                  <h4>Set list</h4>

                  {actData.setList.length > 0 ?
                    actData.setList.map((song, i)=>{
                        return <p key={i}>{song}</p>
                    })
                  :
                  <p>No songs added to set list</p>
                  }
              </div>
              }

            </Box>
        </Modal>
    </div>
  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: "90vw",
    maxHeight: "80vh",
    overflow: "scroll",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };