import React, {useEffect} from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './Context/AuthContext'
import { InboxProvider } from './Context/InboxContext'
//Components
import Landing from './Components/PublicUI/Landing/Landing';
import OurStory from './Components/PublicUI/Landing/OurStory';
import Login from './Components/PublicUI/Login';
import ResetPassword from './Components/PublicUI/ResetPassword'
import Acts from './Components/ArtistUI/Acts';
import Find from './Components/PublicUI/Find';
import PublicUI from './Components/PublicUI/PublicUI';
import ArtistList from './Components/ArtistUI/Admin/ArtistList';
import RequestList from './Components/ArtistUI/RequestList';
import Request from './Components/ArtistUI/Request';
import ArtistInbox from './Components/ArtistUI/Inbox';
import Chat from './Components/ArtistUI/Chat';
import ClientChat from './Components/ClientUI/ClientChat';
import FindArtists from './Components/PublicUI/Artists/FindArtists';
import ArtistProfile from './Components/PublicUI/Artists/ArtistProfile';
import RequireAuth from './Components/RequireAuth'
import ArtistUI from './Components/ArtistUI/ArtistUI'
import Calendar from './Components/ArtistUI/Calendar'
import Quote from './Components/ClientUI/Quote';
import ActManager from './Components/ArtistUI/ActManager';
import RequireAdminAuth from './Components/RequireAdminAuth';
import AOS from 'aos'
import TempLanding from './Components/PublicUI/Landing/TempLanding';
import ActList from './Components/ArtistUI/Admin/ActList';
import ClientUI from './Components/ClientUI/ClientUI'
import Wedding from './Components/ClientUI/Wedding';
import ClientInbox from './Components/ClientUI/Inbox';
import ClientTerms from './Components/PublicUI/ClientTerms';
import Blogs from './Components/PublicUI/Blog/Blogs';
import Blog from './Components/PublicUI/Blog/Blog';
import BookingList from './Components/ArtistUI/Admin/BookingList';
import SystemReport from './Components/ArtistUI/Admin/SystemReport';
import ScrollToTop from './Components/ScrollToTop';
import Hotjar from '@hotjar/browser';

function App() {

  //Hotjar for user heatmaps
  const siteId = process.env.REACT_APP_HOTJAR;
  const hotjarVersion = 6;

  useEffect(() => {
    AOS.init();
    AOS.refresh()
    Hotjar.init(siteId, hotjarVersion);
  }, [])

  let url = window.location.href;



  return (
    <AuthProvider>
    <InboxProvider>
    <ToastContainer position='top-center' />
    {console.log(url)}

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
         {/*Public routes */}

        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/client-terms' element={<ClientTerms />} />

        {url.includes("localhost") || url.includes("test.firstdancemusic") || url.includes("fdm-test.netlify") ?
          <Route element={<RequireAdminAuth />} >
            <Route path='/' element={<PublicUI />}>
              <Route index element={<Landing />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/blogs/:id' element={<Blog />} />
              <Route path='/landing' element={<TempLanding />} />
              <Route path='/our-story' element={<OurStory />} />
              <Route path='/find' element={<Find />} />
              <Route path='/find/artists' element={<FindArtists />} />
              <Route path='/find/artists/:id' element={<ArtistProfile />} />
            </Route>
          </Route>
          :
            <Route path='/' element={<PublicUI />}>
              <Route index element={<Landing />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/blogs/:id' element={<Blog />} />
              <Route path='/landing' element={<TempLanding />} />
              <Route path='/our-story' element={<OurStory />} />
              <Route path='/find' element={<Find />} />
              <Route path='/find/artists' element={<FindArtists />} />
              <Route path='/find/artists/:id' element={<ArtistProfile />} />
            </Route>
        }

        {/* client Auth routes */}
        <Route element={<RequireAuth authRole="client" />}>
          <Route path='/clients' element={<ClientUI />}>
            <Route index element={<Wedding />} />
            <Route path='/clients/wedding' element={<Wedding />} />
            <Route path='/clients/inbox' element={<ClientInbox />} />
            <Route path='/clients/inbox/:id' element={<ClientChat />} />
            <Route path='/clients/quote/:id' element={<Quote />} />
          </Route>
        </Route>

        {/*artists Auth routes*/}
        <Route path="/artists" element={<RequireAuth authRole="artist" />}>
          <Route path='/artists' element={<ArtistUI />}>
            <Route index element={<Calendar />} />
            <Route path='/artists/acts' element={<Acts />} />
            <Route path='/artists/acts/:id' element={<ActManager />} />
            <Route element={<RequireAdminAuth />} >
              <Route path='/artists/list' element={<ArtistList />} />
              <Route path='/artists/act-list' element={<ActList />} />
              <Route path='/artists/bookings' element={<BookingList />} />
              <Route path='/artists/system' element={<SystemReport />} />
            </Route>
            <Route path='/artists/requests' element={<RequestList />} />
            <Route path='/artists/requests/:id' element={<Request />} />
            <Route path='/artists/inbox' element={<ArtistInbox />} />
            <Route path='/artists/inbox/:id' element={<Chat />} />
          </Route>
        </Route>

      </Routes>
    </BrowserRouter>
  </InboxProvider>
  </AuthProvider>
  );
}

export default App;
