import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Featured.css'
import ProfileThumbnail from './ProfileThumbnail';
import Slider from "react-slick";

export default function Featured() {

  useEffect(() => {
    getFeatured()
  }, [])

  const [featuredActs, setFeaturedActs] = useState([])

  const getFeatured = () => {
    axios.get(`${process.env.REACT_APP_API}/acts/get-featured-acts`)
      .then((res) => {
        setFeaturedActs(res.data)
        console.log("Featured Artists", res.data)
      })
      .catch((e)=>console.log(e))
  }

  return (

    <div id='featured-artists'>
      <h2 style={{color:'#313131', fontFamily:'Mont', textAlign: 'center', fontSize: 28, margin: '1rem auto'}}>Featured Artists</h2>

      <div id='feature-scroller'>
        {
          featuredActs.map((artist, i) => (

              <ProfileThumbnail key={i} artist={artist}/>

            ))
        }
      </div>
        
  </div>



  )
}

