import React, { useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom'

export default function ViewBooking({event, view, setView}) {

  let navigate = useNavigate()

  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")
  const [notes, setNotes] = useState("")
  const [requested, setRequested] = useState(false)
  const [artistId, setArtistId] = useState("")

  useEffect(() => {
    if (event) {
      setTitle(event.title);
      setDate(event.startStr);
      setNotes(event.extendedProps.notes);
      setRequested(Boolean(event.extendedProps.requestId))
      setArtistId(event.extendedProps.artistId)
    } else {
      return
    }
  }, [event])

  return (
    <Modal
        open={view}
        onClose={()=>setView(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {requested ? (
          <p onClick={() => navigate(`/artists/requests/${event.extendedProps.requestId}`)}
            style={{ cursor: 'pointer' }}
            title="Show Request">
            Client requested bookings are read only.</p>
        ) : (
          <p>Amend your manually created booking.</p>
        )}
          
        <TextField
          fullWidth 
          sx={{my:1}}
          label='Booking Title eg. Wedding'
          value={title}
          disabled={requested}
          onChange={(e) => setTitle(e.target.value)}/>

        <TextField
          fullWidth
          sx={{my:1}}
          label="Date of booking"
          type="date"
          value={date}
          disabled={requested}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          fullWidth
          sx={{my:1}}
          label="Notes"
          multiline rows={2}
          value={notes}
          disabled={requested}
          onChange={(e)=>setNotes(e.target.value)}/>

        <Button
          variant='contained'
          disabled={requested}
          sx={{backgroundColor: '#a3837f', mt:2}} >
          Update Booking
        </Button>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflow: 'scroll'
};