import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import './Wedding.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify'


export default function Wedding() {

    let { currentUser } = useAuth()
    const [requests, setRequests] = useState("")
    const [schedule, setSchedule] = useState("")
    const [expandedRequests, setExpandedRequests] = useState([0]);
    const [expandedBookings, setExpandedBookings] = useState([0]);
    const [open, setOpen] = useState(false);
    const [eventId, setEventId] = useState("");
    const [eventText, setEventText] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [isNewEntry, setIsNewEntry] = useState(true);
    const [modalTitle, setModalTitle] = useState("");

    let startRef = useRef()
    let endRef = useRef()

    useEffect(() => {
        getRequests()
        getSchedule()
    }, [])

    const getRequests = () => {
        axios.post(`${process.env.REACT_APP_API}/requests/get-requests-with-quotes-by-client-id`, {id: currentUser.uid})
        .then((res) => {
            setRequests(res.data)
        })
        .catch((e) => console.log(e))
    }

    const getSchedule = () => {
        axios.post(`${process.env.REACT_APP_API}/bookings/get-schedule-by-client-id`, {id: currentUser.uid})
        .then((res) => {
            setSchedule(res.data)
        })
        .catch((e) => console.log(e))
    }

    const toggleRequest = (index) => {
        if (expandedRequests.includes(index)) {
            // If the request is expanded, collapse it
            setExpandedRequests(expandedRequests.filter((item) => item !== index));
        } else {
            // If the request is collapsed, expand it
            setExpandedRequests([...expandedRequests, index]);
        }
    };

    const toggleBooking = (index) => {
        if (expandedBookings.includes(index)) {
            // If the booking is expanded, collapse it
            setExpandedBookings(expandedBookings.filter((item) => item !== index));
        } else {
            // If the request is collapsed, expand it
            setExpandedBookings([...expandedBookings, index]);
        }
    };

    const addEvent = () => {
        if (!eventText || !startTime || !endTime) {
            toast.error("Please add a description plus approximate times")
        } else {
            let payload = {
                clientId: currentUser.uid,
                startTime,
                endTime,
                event: eventText
            }
            axios.post(`${process.env.REACT_APP_API}/bookings/edit-wedding-schedule`, payload)
                .then(() => {
                    handleClose()
                    getSchedule()
                })
                .catch((e) => console.log(e))
        }
    }

    const modifyEvent = () => {
        if (!eventText || !startTime || !endTime) {
            toast.error("Please add a description plus approximate times")
        } else {
            let payload = {
                clientId: currentUser.uid,
                eventId,
                startTime,
                endTime,
                event: eventText
            }
            axios.post(`${process.env.REACT_APP_API}/bookings/edit-wedding-schedule`, payload)
                .then(() => {
                    handleClose()
                    getSchedule()
                })
                .catch((e) => console.log(e))
        }
    }

    const deleteEvent = () => {
        const payload = {
            clientId: currentUser.uid,
            eventId,
        };

        axios
            .post(`${process.env.REACT_APP_API}/bookings/remove-schedule-entry`, payload)
            .then((response) => {
                handleClose()
                getSchedule()
            })
            .catch((error) => {
                console.error('Error removing schedule entry:', error);
            });
    }

    const handleClose = () => {
        setOpen(false);
        resetForm();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const resetForm = () => {
        setEventText('');
        setStartTime('');
        setEndTime('');
    };

    const handleOpenSchedule = (selectedEvent) => {
        setModalTitle("Add a new schedule entry");
        setIsNewEntry(true);
        handleOpen();
    }

    const handleEditSchedule = (selectedEvent) => {
        setEventId(selectedEvent._id);
        setEventText(selectedEvent.event);
        setStartTime(selectedEvent.startTime);
        setEndTime(selectedEvent.endTime);

        setIsNewEntry(false);
        setModalTitle("Modify/Delete the schedule entry");
        handleOpen();
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h2>Wedding Details</h2>
            <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-evenly', alignItems:'flex-start', flexWrap:'wrap'}}>

                <div style={{display:'flex', flexDirection: 'column'}}>
                    <Link to="/find" title="Add New Request" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Fab sx={{ backgroundColor: '#ecbdba', my: '1rem' }} aria-label="add">
                            <AddIcon sx={{ color: '#fff' }} />
                        </Fab>
                        <span style={{ visibility: 'hidden' }}>Add New Request</span>
                    </Link>

                    {requests
                        .length > 0 ? (
                        requests
                        .filter((request) => !request.quoteId)
                        .map((request, index) => (
                            <div key={index} style={{ marginBottom: '20px', textAlign: 'center' }}>
                                <h3
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => toggleRequest(index)}
                                    >
                                    Request - {request.info?.actType} - {request.info?.startTime}
                                    <FontAwesomeIcon
                                        icon={expandedRequests.includes(index) ? faAngleUp : faAngleDown}
                                        style={{ marginLeft: '10px' }}
                                    />
                                </h3>
                                {expandedRequests.includes(index) && (
                                    <div>
                                        <p>Client name - {request.name}</p>
                                        <p>Date of wedding - {moment(request.date).format('ddd, DD MMM YYYY')}</p>
                                        <p>Act requested - {request.info?.actType}</p>
                                        <p>Start time - {request.info?.startTime}</p>
                                        <p>End time - {request.info?.endTime}</p>
                                        <p>Venue - {request.info?.venue}</p>
                                        <p>Location - {request.info?.location}</p>
                                        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                            <p>Notes - {request.notes}</p>
                                        </div>

                                        {/* Display associated quotes */}
                                        {request.quotes && request.quotes.length > 0 ? (
                                        <div>
                                            <h4>Quotes for this request:</h4>
                                            {request.quotes.map((quote, quoteIndex) => (
                                                <div key={quoteIndex}>
                                                    <p>
                                                        <Link to={`/clients/quote/${quote._id}`} style={{ marginRight: '10px' }}>
                                                            View #{quoteIndex + 1} :  £{quote.customerGross}.
                                                        </Link>
                                                        <Link to={`/clients/inbox/${quote.chatId}`}>
                                                            See chat
                                                        </Link>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        ) : (
                                            <h4>No quotes yet for this request</h4>
                                        )}

                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <h3>Create/add a wedding request</h3>
                    )}
                </div>


                <div style={{display:'flex', flexDirection: 'column'}}>
                    <div style={{ height: '105px', visibility: 'hidden' }}></div>

                    {requests
                        .length > 0 ? (
                            requests
                            .filter((request) => request.quoteId)
                            .map((request, index) => (
                            <div key={index} style={{ marginBottom: '20px', textAlign: 'center' }}>
                                <h3
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => toggleBooking(index)}
                                    >
                                    Booked - {request.info?.actType} - {request.info?.startTime}

                                    <FontAwesomeIcon
                                        icon={expandedBookings.includes(index) ? faAngleUp : faAngleDown}
                                        style={{ marginLeft: '10px' }}
                                    />
                                </h3>
                                {expandedBookings.includes(index) && (
                                    <div>
                                        <p>Client name - {request.name}</p>
                                        <p>Date of wedding - {moment(request.date).format('ddd, DD MMM YYYY')}</p>
                                        <p>Act requested - {request.info?.actType}</p>
                                        <p>Start time - {request.info?.startTime}</p>
                                        <p>End time - {request.info?.endTime}</p>
                                        <p>Venue - {request.info?.venue}</p>
                                        <p>Location - {request.info?.location}</p>
                                        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                                            <p>Notes - {request.notes}</p>
                                        </div>

                                        {/* Display associated quotes */}
                                        <div>
                                            <h4>The quote for this booking:</h4>
                                            {request.quotes
                                                .filter((quote) => quote._id === request.quoteId)
                                                .map((quote, quoteIndex) => (
                                                    <div key={quoteIndex}>
                                                        <p>
                                                            <Link to={`/clients/quote/${quote._id}`} style={{ marginRight: '10px' }}>
                                                                View #{quoteIndex + 1} :  £{quote.customerGross}.
                                                            </Link>
                                                            <Link to={`/clients/inbox/${quote.chatId}`}>
                                                                See chat
                                                            </Link>
                                                        </p>
                                                    </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (null)}
                </div>


                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Fab sx={{backgroundColor: '#ecbdba', my: '1rem'}} aria-label="add" onClick={() => handleOpenSchedule()}>
                        <AddIcon sx={{ color: '#fff' }} />
                    </Fab>
                    <span style={{ visibility: 'hidden' }}>Add New Schedule Entry</span>
                    {schedule ? (
                        <>
                            <h3>Schedule for the day:</h3>
                            {schedule.events.map((item, index) => (
                                <div
                                    key={index}
                                    style={{ lineHeight: '0.7', cursor: 'pointer' }}
                                    onClick={() => handleEditSchedule(item)}
                                >
                                    <p>{item.event} - {item.startTime} to {item.endTime}</p>
                                </div>
                            ))}
                        </>
                    ) : (
                        <h3>Set up the schedule for your day</h3>
                    )}
                </div>
            </div>


            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                    resetForm();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        {modalTitle}
                    </Typography>

                    <TextField
                        fullWidth
                        sx={{ marginTop: '20px', marginBottom: '16px' }}
                        label="Event description"
                        value={eventText}
                        onChange={(e) => setEventText(e.target.value)}/>

                    <input
                        className='timing-input'
                        style={{marginRight:'5%', flex: '1'}}
                        ref={startRef}
                        type="text"
                        placeholder='Start time (est.)'
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        onFocus={() => (startRef.current.type = "time")}
                        onBlur={() => (startRef.current.type = "text")}
                    />

                    <input
                        className='timing-input'
                        style={{flex: '1'}}
                        ref={endRef}
                        type="text"
                        placeholder='End time (est.)'
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        onFocus={() => (endRef.current.type = "time")}
                        onBlur={() => (endRef.current.type = "text")}
                    />

                    <Button
                        variant='contained'
                        sx={{ backgroundColor: '#ecbdba', mt: 3, mx: isNewEntry ? 3 : 1 }}
                        onClick={isNewEntry ? addEvent : modifyEvent} // Use addEvent for new, modifyEvent for existing
                    >
                        {isNewEntry ? 'Add event' : 'Modify'}
                    </Button>

                    {!isNewEntry && (
                        <Button
                            variant='contained'
                            color='error'
                            sx={{ mt: 3, mx: 1 }}
                            onClick={deleteEvent}
                        >
                            Delete
                        </Button>
                    )}

                    <Button
                        variant='outlined'
                        sx={{ mt: 3, mx: isNewEntry ? 3 : 1 }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth:'95vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'scroll'
  };