// Function to extract text from URL
export const ExtractEmbedFromVideoUrl = (videoLink) => {
  /*
  **  cover the expected formats:
  **    https://youtu.be/VIDEO_ID
  **    https://www.youtube.com/embed/VIDEO_ID
  **    https://www.youtube.com/v/VIDEO_ID
  **    https://www.youtube.com/watch?v=VIDEO_ID
  **    https://www.youtube.com/watch?feature=player_embedded&v=VIDEO_ID
  **    https://www.youtube.com/shorts/VIDEO_ID
  **  where VIDEO_ID is an alphanumeric of length 11
  **  and enforce https presence, http is not expected
  */

  const youtubeRegex = /(?:https?:\/\/(?:youtu\.be\/|www\.youtube\.com\/embed\/|www\.youtube\.com\/v\/|www\.youtube\.com\/watch\?v=|www\.youtube\.com\/watch\?feature=player_embedded&v=|www\.youtube\.com\/shorts\/))(?<videoId>[A-Za-z0-9_-]{11})\/?/;

  const match = videoLink.match(youtubeRegex);

  if (match && match.groups && match.groups.videoId) {
    return match.groups.videoId;
  } else {
    return "";
  }
}


export const SplitName = (name) => {
  // Remove leading and trailing spaces
  name = name.trim();

  // Split the name by spaces
  const parts = name.split(/\s+/);

  if (parts.length === 1) {
    // Only one part, consider it as the firstName
    return { firstName: parts[0], lastName: '' };
  } else {
    // More than one part, consider the last part as the lastName
    const lastName = parts.pop();
    const firstName = parts.join(' ');
    return { firstName, lastName };
  }
}
