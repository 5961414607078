import React, { useState, useEffect } from 'react'
import './ArtistContainer.css'
import { Link } from 'react-router-dom'
import play from '../../../Assets/play.png'
import { ExtractEmbedFromVideoUrl } from '../../../Utilities.js'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import YouTube from 'react-youtube';
import like from '../../../Assets/Icons/like.png'
import likedIcon from '../../../Assets/Icons/liked.png'

export default function ArtistContainer({artist}) {

  const [showVideo, setShowVideo] = useState(false)

  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      controls: 1,
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
      fs: 1,
    }
  }

  const [liked, setLiked] = useState(false)

  useEffect(() => {
    isLiked()
  }, [artist._id])

  const isLiked = () => {
    let likedStatus = localStorage.getItem(`${artist._id}`)
    if(likedStatus == 'true'){
      setLiked(true)
    } else {
      setLiked(false)
    }
  }

  const toggleLike = (value) => {
    setLiked(value)
    localStorage.setItem(`${artist._id}`, value);
  }

  return (
    <div className='artist-container'>
        {
        showVideo ?
          <YouTube
            className='artist-container-img'
            videoId={artist.video && ExtractEmbedFromVideoUrl(artist.video)}
            opts={opts}
            onPlay={(e) => e.target.setPlaybackQuality('small')}
          />
        :
          artist.video
          ?

          <>

            <button className='play-btn hover' onClick={()=> setShowVideo(true)}>
              Quick View
            </button>
            <img className='favourite hover' src={liked ? likedIcon : like} alt="Favourite wedding musician" onClick={() => toggleLike(!liked)}/>

            <LazyLoadImage
              className='artist-play-img'
              src={artist.primaryImg}
              alt={artist.actName}
              effect="blur"
            />
          </>

          :
          <>
            <img className='favourite hover' src={liked ? likedIcon : like} alt="Favourite wedding musician" onClick={() => toggleLike(!liked)}/>
            <LazyLoadImage
              className='artist-container-img'
              src={artist.primaryImg}
              alt={artist.actName}
              effect="blur"
            />

          </>
          

        }
        <div className='artist-container-info'>
          <h3 className='act-name'>{artist.actName}</h3>
          <Link to={`/find/artists/${artist._id}`}><button className='view-artist-btn'>View artist</button></Link>
        </div>
    </div>
  )
}
