import React, { useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import { toast } from 'react-toastify'
import { auth } from '../../Firebase'
import logo from '../../Assets/logo-stacked.png'

export default function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  let navigate = useNavigate()

  const handleLogin = () => {
    if (!email || !password) {
      toast.error("Please input all fields")
    } else {
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Signed in
        auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.role === 'client') {
            navigate('/clients')
          } else {
            // otherwise this means they are an artist there are no other roles as yet
            navigate('/artists')
          }
        })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }

  const resetPassword = () => {
    if (!email) {
      toast.error("Please input email")
    } else {
      sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent!")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage)
      });
    }
  }


  return (
    <div id='login-page'>
      <img id='login-logo' src={logo}/>
      <div id='credentials-container'>
        <input className='login-input' placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        <input className='login-input' type='password' placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)}/>
        <button id='login-btn' onClick={handleLogin}>Login</button>

        <p id='forgotten' onClick={resetPassword}>Forgotten Password?</p>
      </div>
    </div>
  )
}
