import React, { useEffect, useState} from 'react'
import './FindArtists.css'
import axios from 'axios'
import ArtistContainer from './ArtistContainer'
import hearts from '../../../Assets/Icons/favourite.png';
import { Locations, ActTypes } from '../../../Constants';
import Skeleton from '@mui/material/Skeleton';
import { useSearchParams } from 'react-router-dom';

export default function FindArtists() {

  const [artists, setArtists] = useState([])

  // Filters
  const [locationQuery, setLocationQuery] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = useState(searchParams.get('search') || '');
  const [loading, setLoading] = useState(true)
  
    // Update URL query parameter whenever category changes
    useEffect(() => {
      setSearchParams({ search: category });
    }, [category, setSearchParams]);
    

  useEffect(() => {
    if(category === "Favourites"){
      handleFavourites()
    } else {
      filter()
    }
  }, [category])

  const getArtists = () => {
    axios.get(`${process.env.REACT_APP_API}/acts/get-acts`)
    .then((res) => {
      setArtists(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
    .finally(() => {
      setLoading(false);
    });
  }

  const handleFavourites = () => {
    // Retrieve all items from local storage
    const allItems = { ...localStorage };

    // Initialize an array to store keys with value 'true'
    const trueKeys = [];

    // Iterate over each key-value pair in local storage
    for (const key in allItems) {
      if (allItems.hasOwnProperty(key)) {
        // Check if the value is 'true'
        if (allItems[key] === 'true') {
          trueKeys.push(key); // If true, push the key to the array
        }
      }
    }

    axios.post(`${process.env.REACT_APP_API}/acts/get-favourite-acts-by-local-storage`, {ids: trueKeys})
      .then((res) => {
        setArtists(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const filter = () => {
    if (!locationQuery && !category) {
      getArtists()
    } else {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_API}/acts/get-filtered-acts`, {location: locationQuery, category})
      .then((res) => {
        setArtists(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }

  return (
    <div id='find-artist-page'>
     {/* <div id='filters'>
        <div>
          <label style={{marginRight: 5}}>Category</label>

          <select className='filter-opt' value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="">Select</option>
            {ActTypes.map((actType) => (
              <option key={actType.name} value={actType.name}>
                {actType.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{marginRight: 5}}>location</label>
          <select className='filter-opt' value={location} onChange={(e) => setLocation(e.target.value)} >
            <option value="">Select</option>
            {Locations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>
        <button id='filter-btn' onClick={filter}>
          <FilterAltIcon sx={{color:'white'}}/>
          Filter
        </button>
            </div> */}

      <div id='categories'>
        {ActTypes.map((icon, i) => {
          return (
            <div 
              key={i} 
              className={category === icon.name ? 'category-icon-active hover' : 'category-icon hover'}
              onClick={() => setCategory(icon.name)}
            >
              <img src={icon.icon} style={{height: 40, width: 40}} alt='First Dance Music Icon'/>
              <p>{icon.name}</p>
            </div>
          )
        })}

            <div 
              className={category === "Favourites" ? 'category-icon-active hover' : 'category-icon hover'}
              onClick={() => setCategory("Favourites")}
            >
              <img src={hearts} style={{height: 40, width: 40}} alt='First Dance Music Icon'/>
              <p>Liked</p>
            </div>

      </div>

      <div id='artist-list'>
         {loading ?
          <>
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
            <SkeletonLoader />
          </>
            :
            artists.map((artist, i) => {
              return <ArtistContainer key={i} artist={artist}/>
          })
        }
      </div>
    </div>
  )
}

const SkeletonLoader = () => {
  return(
    <div className='skeleton-loader'>
      <Skeleton variant="rectangular" width="100%" height={200} style={{borderRadius: 8}} />
      <Skeleton width="60%" height={60}/>
      <Skeleton width="25%" height={60} />
    </div>
  )
}
