import React from 'react'
import './Landing.css'
import Featured from './Featured'
import Hero from './Hero'
import HowItWorks from './HowItWorks'
import FAQ from './FAQ'
import HowItWorksMobile from './HowItWorksMobile'

export default function Landing() {
  return (
    <div id='landing'>
      <Hero />
      <Featured/>
      <HowItWorks />
      <HowItWorksMobile />
      <FAQ />
  
    </div>
  )
}
