import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import './Chat.css'
import axios from 'axios'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext'
import { UseInbox } from '../../Context/InboxContext'

// artist side chat, i.e. for messages from artist to client
export default function Chat() {

    let { id } = useParams()

    let { getUnreadChats } = UseInbox()

    let { currentUser } = useAuth()
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState("")
    const [chat, setChat] = useState("")

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
      scrollToBottom()
    }, [messages]);

    useEffect(() => {
        getMessages()
        getChat()
    },[])

    const getChat = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-artist-chat-by-id`, {chatId: id})
        .then((res)=>{
            setChat(res.data)
            console.log("Chat",res.data)
        })
        .catch((e) => console.log(e))
    }

    const getMessages = () => {
        axios.post(`${process.env.REACT_APP_API}/chats/get-messages-by-chat-id`, {chatId: id})
        .then((res) => {
            setMessages(res.data)
            getUnreadChats()
        })
        .catch((e) => console.log(e))
    }

    const sendMessage = () => {
        if (!message) {
            return
        } else{
            axios.post(`${process.env.REACT_APP_API}/chats/send-artist-message`, {
                chatId: id,
                clientEmail: chat.clientEmail,
                message,
                timeStamp: new Date(),
                sentBy: "Artist"
            })
            .then((res) => {
                setMessage("")
                getMessages()
                console.log(res.data)
            })
            .catch((e)=>console.log(e))
        }
    }

    return (
        <div id='chat-page'>
            {messages.map((msg, i) => {
                let lineBreaks = msg.message.split('-')
                return <div key={i} className={msg.sentBy === "Client" ? 'client-msg-container' : undefined}>
                    <div  className={msg.sentBy === "Artist" ? 'artist-msg' : 'client-msg'}>
                        {lineBreaks.map((line, i) => {
                            const quoteRegex = /Quote ([a-fA-F0-9]{24}) (.*)/;
                            const actRegex = /For proposed act (.*?): (.*)/;
                            const quoteMatch = line.match(quoteRegex);
                            const actMatch = line.match(actRegex);
                            if (quoteMatch) {
                                const requestURL = `/artists/requests/${chat.requestId}`;
                                return (
                                    <p key={i} >
                                        <a  style={{fontFamily: 'Mont', color:'grey'}} href={requestURL} target="_blank" rel="noopener noreferrer">
                                            View the quote
                                        </a>{" "}
                                        you provided to the client
                                    </p>
                                )
                            } else if (actMatch) {
                                const prefix = line.split(actMatch[1])[0]
                                const actURL = `/find/artists/${actMatch[2]}`
                                return (
                                    <p key={i}>
                                        <span>{prefix}</span>
                                        <a  style={{fontFamily: 'Mont', color:'grey'}} href={actURL} target="_blank" rel="noopener noreferrer">
                                            {actMatch[1]}
                                        </a>
                                    </p>
                                )
                            } else {
                                const myLines = String(line).split("\n");
                                return (
                                    <p key={i}>
                                        {myLines.map((line, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {line} <br />
                                                </React.Fragment>
                                            );
                                        })}
                                    </p>
                                )
                            }
                            })}

                        <p className='timestamp'>{moment(msg.timeStamp).format('HH:mm A, DD MMM')}</p>
                    </div>
                </div>
            })}

            <div id='message-container' ref={messagesEndRef}>
                <textarea id='msg-box' value={message} onChange={(e)=>setMessage(e.target.value)}/>
                <button id='send-btn' onClick={sendMessage}>Send to client</button>
            </div>
        </div>
    )
}
