import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ActContainer.css'

export default function ActContainer({act}) {

    let navigate = useNavigate()

  return (
    <div className='act-container' style={{backgroundImage:`url(${act.primaryImg})`, backgroundPosition:'center', backgroundSize:'cover'}} onClick={()=> navigate(`/artists/acts/${act._id}`)}>
        <div className='act-overlay'>
            <h2>{act.actName}</h2>
            {!act.published && <p style={{color:'#FFBF00', fontSize:18, fontWeight:700}}>Unpublished</p>}
        </div>
    </div>
  )
}
