import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import fb from '../../Assets/Icons/facebook.webp'
import insta from '../../Assets/Icons/instagram.webp'
import youtube from '../../Assets/Icons/youtube.webp'

export default function Footer() {
  return (
    <footer>
        <p>Copyright {new Date().getFullYear()} First Dance Music</p>

        <div style={{textAlign:'center', margin: '1rem auto'}}>
          <h4 style={{fontFamily:'Mont'}}>Follow Us</h4>
          <a href='https://www.facebook.com/wearefirstdance' target='_blank'>
            <img className='footer-social' src={fb} alt='Social media link for First Dance Music wedding music booking platform'/>
          </a>
          <a href='https://www.instagram.com/wearefirstdance/' target='_blank'>
            <img className='footer-social' src={insta} alt='Social media link for First Dance Music wedding music booking platform'/>
          </a>
          <a href='https://www.youtube.com/channel/UCyVp9AMe4bCu16t7HNACC1w' target='_blank'>
            <img className='footer-social' src={youtube} alt='Social media link for First Dance Music wedding music booking platform'/>
          </a>

        </div>

        <div style={{display:'flex', flexDirection:'column'}}>
          <Link className='footer-link' to={'/client-terms'}>Client Terms & Conditions</Link>
          <a href='https://www.freeprivacypolicy.com/live/114954b6-144b-4016-aaa3-2a360fc61de2' target={'_blank'} className='footer-link'>Privacy Policy</a>
          <Link className='footer-link' to={'/blogs'}>Blog</Link>
        </div>
    </footer>
  )
}
